import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import { TribeModel } from "../_models/tribe";
import { MobilizerModel } from "../_models/mobilizer";
import { CustomNotificationModel } from "../_models/custom-notification";
import { MasterFeatureListModel } from "../_models/master-feature-list";
import { FeedbackModel } from "../_models/feedback";
import { BadgeModel } from "../_models/badge";
import { FeedModel } from "../_models/feed";
import { CommentModel } from "../_models/comment";
import { TribeDetailsModel } from "../_models/tribe-details";
import { PurchasedPlanModel } from "../_models/purchased-plan";
import { SubscriptionPlanModel } from "../_models/subscription-plan";
import { environment } from "src/environments/environment";
import * as CryptoJS from 'crypto-js';
var httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

const httpOptionsBasicAuthToken = {
  headers: new HttpHeaders({
       "Content-Type": "application/json",
     //  Authorization: "Bearer ",
       Authorization: `Basic ${environment.BASETOKEN}`,
     }),};
const httpOptionsAuth = {
  headers: new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded",
  }),
};

const httpAuthBody = new HttpParams()
   .set("client_id", environment.CLIENT_ID)
   .set("client_secret", environment.CLIENT_SECRET)
   .set("password", environment.passcode)
   .set("username", environment.user)
   .set("grant_type", environment.grant);
const httpOptionsImg = {
  headers: new HttpHeaders({
  //  Authorization: `Basic ${environment.BASETOKEN}`,
  }),
};

const httpOptionsImgWithReponseType = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
  responseType: "blob" as "json",
};

const httpOptionsDownload = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
  responseType: "blob" as "json",
};

const httpOptionsUpload = {
  headers: new HttpHeaders({
    "Content-Type": "multipart/form-data",
  }),
  responseType: "blob" as "json",
};

//const unitType1 =this.tribeService.getLocal("trole");
@Injectable({ providedIn: 'root' })
export class TribeService {
  public envName = environment.env;
  /*************** qa ***************/
  private baseURL: string = environment.BASE_URL; //qa
  private authTokenURL: string = environment.AUTH_BASE_URL + "auth/realms/cvp/protocol/openid-connect/token"; //qa
  imageUploadBaseURL: string = environment.BASE_IMAGES_URL; //qa
  imageCarBaseURL: string = environment.imageCarQueryString+"/"; //qa dev, qa & staging
  /*************** qa end ***************/

  modelColor: any;
  carImageFor: any;
  tribeURL: string = this.baseURL + this.getUserRole() + "/tribe";
  authToken: string = "";
  imageDimensionFeed = { width: 500, height: 250 };
  imageDimensionGallery = { width: 360, height: 480 };
  tribeId: Number;
  tribesCount: number = 0;
  imgPathForTribe = "tribe-test";
  imgPathForBadges = "badges-test";
  imgPathForFeeds = "feeds-test";
  imgPathForNotification = "notification-test";
  imgPathForGallery = "gallery-test";
  notificationTypeList = {
    CUSTOM: "Custom",
    TRIBE: "Tribe",
    //DRIVING_BEHAVIOUR: "Driving behaviour",
  };

  tcuManufacturer = [{ name: "Bosch", title: "Bosch" }];


  productLinePv = [
    { name: "Tiago XZ 1.2 P " },
    { name: "Nexon XZA+" },
    { name: "Hexa XT 4x2" },
    { name: "Nexon (P) XZ+" },
    { name: "Nexon (P) XZ+ DT (O) New" },
    { name: "Harrier XT" },
    { name: "Nexon XZ+" },
    { name: "Nexon (D) XZA+" },
    { name: "Nexon EV XZ+" },
    { name: "Tiago (P) NRG" },
    { name: "Nexon (P) XZ+ (O) New" },
    { name: "Altroz XZ+" },
    { name: "Altroz XZ(O) 1.2 P" },
    { name: "Tiago XZ+ 1.2 P (DT)" },
    { name: "Nexon (D) XZ+ (O) New" },
    { name: "Nexon (P) XM New" },
    { name: "Nexon (P) XZA+" },
    { name: "Nexon (P) XZ+ New" },
    { name: "Nexon (P) XZA+ New" },
    { name: "Nexon (D) XZ+" },
    { name: "Tiago (P) XZA+ DT New" },
    { name: "Tigor XZ(O) 1.2 P " },
    { name: "Tigor XZ+ 1.2 P" },
    { name: "Nexon (P) XZ New" },
    { name: "Nexon (P) XZA+ DT (O) New " },
    { name: "Nexon (D) XZ+ DT (O) New " },
    { name: "Nexon (D) XZA+ (O) New" },
    { name: "Altroz XZ 1.2 P " },
    { name: "Nexon (P) XZA+ (O) New" },
    { name: "Harrier XZ" },
    { name: "Harrier XZA" },
    { name: "Hexa XTA 4x2" },
    { name: "Harrier (D) XZA+" },
    { name: "Gravitas (D) XZA+" },
    { name: "Gravitas XZA" },
  ];

  featureType = [
    { name: "REMOTE COMMAND", title: "REMOTE_COMMAND" },
    { name: "HEALTH", title: "HEALTH" },
    { name: "ALERT", title: "ALERT" },
    { name: "OTHER", title: "OTHER" },
  ];
  carModel = [
    { name: "Baleno", title: "Baleno" },
    { name: "Nexon", title: "Nexon" },
  ];
  badgeCategories = [
    { name: "SCORE_BADGE", title: "Score badge" },
    { name: "EFFICIENCY_BADGE", title: "Efficiency badge" },
    { name: "DISTANCE_BADGE", title: "Distance badge" },
  ];
  modelTypes = [{ name: "nexon", title: "Tata Nexon" }];
  //modelTypesEv = [{ name: "nexon_ev", title: "Tata Nexon EV" }];
  platform = [
    { name: "android", title: "Android" },
    { name: "ios", title: "iOS" },
  ];
  status = [
    { name: "IMMOBILIZED", title: "IMMOBILIZED" },
    { name: "MOBILIZED", title: "MOBILIZED" },
    { name: "CLOSED", title: "CLOSED" },
  ];

  modelColorPvWithImages = [
    { name: "Pristin White", value: "pristin_whte", src: '/assets/image/nexon-ev-pristine-white.png' },
    { name: "Pure Silver", value: "pure_silver", src: '/assets/image/nexon-ev-pure-silver.png' },
    { name: "Atlas Black", value: "black_onyx", src: '/assets/image/nexon-ev-tectonic-blue.png' },
    { name: "Tectonic Blue", value: "ev_teal_blue", src: '/assets/image/nexon-ev-tectonic-blue.png' },
    { name: "Daytona Grey", value: "daytona_grey", src: '/assets/image/nexon-ev-pure-silver.png' },
    { name: "Pristine White", value: "pristine_white", src: '/assets/image/nexon-ev-pristine-white.png' },
    { name: "Intensiteal", value: "intensiteal", src: '/assets/image/nexon-ev-tectonic-blue.png' },
  ];

  nexonColorPV = [
    { name: "Foliage Green", value: "foliage" },
    { name: "Flame Red", value: "flame_red" },
    { name: "Fearless Red", value: "flame_red_v2" },
    { name: "Tectonic Blue", value: "tenblu_prewt" },
    { name: "Daytona Grey", value: "daytona_grey" },
    { name: "Calgary White", value: "calgary_whte" },
    { name: "Pure Silver", value: "pure_silver" },
    { name: "Atlas Black", value: "atlas_black" },
    { name: "StarLight Brown", value: "starlight" },
    { name: "Royal Blue", value: "royale_blue" },
    { name: "Grassland Beige", value: "khaki_bkonx" },
    { name: "Creative Ocean", value: "cretve_ocean" },
    { name: "Pure Grey", value: "pure_grey" },
    { name: "Fearless Purple", value: "felrles_purl" },
    { name: "Pristine White", value: "pristin_whte" },
  ];

  punchColorPV = [
    { name: "Tornado Blue", value: "trdblu_prewt" },
    { name: "Atomic Orange", value: "atmorg_bkonx" },
    { name: "Meteor Bronze", value: "metrbz_bkonx" },
    { name: "Tropical Mist", value: "tromst_bkonx" },
    { name: "Orcus White", value: "orcwt_dt" },
    { name: "Daytona Grey", value: "dyngry_bkonx" },
    { name: "Khaki", value: "khaki_bkonx" },
  ];

  harrierColorPV = [
    { name: "Orcus White", value: "orc_white" },
    { name: "Daytona Grey", value: "daytona_grey" },
    { name: "Atlas Black", value: "atl_black_dk" },
    { name: "Royale Blue", value: "roy_blue" },
    { name: "Tropical Mist DT", value: "tro_mist_dt" },
    { name: "Camo Green", value: "camo_green" },
    { name: "Orcus White Dual Tone", value: "orcwt_dt" },
    { name: "Calypso Red Dual Tone", value: "calred_dt" },
    { name: "Calypso Red", value: "cal_red" },
    { name: "Grassland Beige", value: "grlnb_dt_kzr" },
    { name: "Oberon Black", value: "oberon_blk" },
    { name: "Oberon Black", value: "oberon_blk_v2" },

    { name: "Ash Grey", value: "ash_grey" },
    { name: "Coral Red", value: "corred_nctbk" },
    { name: "Sunlit yellow", value: "sunyew_nctbk" },
    { name: "Lunar White", value: "lunwt_nctblk" },
    { name: "Pebble grey", value: "pblgry_nctbk" },
    { name: "Seaweed Green", value: "seawd_green" }
  ];

  //safari
  gravitasColorPV = [
    { name: "Orcus White", value: "or_wht" },
    { name: "Daytona Grey", value: "day_grey" },
    { name: "Royal Blue", value: "roy_blue" },
    { name: "Tropical Mist", value: "tro_mist" },
    { name: "Black Gold", value: "black_gold" },
    { name: "White Gold", value: "white_gold" },
    { name: "Oberon Black", value: "oberon_blk" },
    { name: "Oberon Black", value: "oberon_blk_v2" },
    { name: "Grassland Beige", value: "grlnb_dt_kzr" },
    { name: "Star Light", value: "strlit_jet" },

    { name: "Cosmic Gold", value: "cosgld_nctbk" },
    { name: "Stellar Frost", value: "strft_nctbk2" },
    { name: "Galactic Sapphire", value: "gltspr_nctbk" },
    { name: "Lunar Slate", value: "lunar_slt" },
    { name: "Supernova Copper", value: "sprnova_copr" },
    { name: "Stardust Ash", value: "strash_nctbk" }
  ];

  altrozColorPV = [
    { name: "Avenue White ", value: "avewht_bkonx" },
    { name: "Downtown Red", value: "felred_bkonx" },
    { name: "High Street Gold", value: "chamnp_bkonx" },
    { name: "Skyline Silver", value: "skyline_slvr" },
    { name: "Midtown Grey", value: "midtown_grey" },
    { name: "Moroccan Blue", value: "mrnblu_bkonx" },
    { name: "Opera Blue", value: "opera_blue" },
    { name: "Arcade Grey", value: "arcade_grey" },
    { name: "Royal Blue", value: "royal_blue" },
    { name: "Atomic Orange", value: "atmorg_bkonx" },
  ];

  curvvColorPV = [
    { name: "Pristine White Single Tone", value: "pristin_whte" },
    { name: "Daytona Grey Single Tone" , value: "daytona_grey" },
    { name: "Pure Grey Single Tone", value: "pure_grey" },
    { name: "Flame Red Single Tone", value: "flame_red" },
    { name: "Opera Blue Single Tone", value: "blue_venture" },
    { name: "Pristine White Dual Tone", value: "prsnwt_bkonx" },
    { name: "Daytona Grey Dual Tone", value: "dyngry_bkonx" },
    { name: "Pure Grey Dual Tone", value: "purgry_bkonx" },
    { name: "Flame Red Dual Tone", value: "flmred_bkonx" },
    { name: "Opera Blue Dual Tone", value: "bluvtr_bkonx" },
    { name: "Virtual Sunrise Dual Tone", value: "virsun_bkonx" },
    { name: "Goldessence Dual Tone", value: "gldesn_bkonx" }
  ];

  carImageForPV = {
    android: [
      {
        name: "back_door_close",
        title: "Back door close",
        image: [
          { quality: "hdpi", title: "hdpi", width: 262, height: 525 },
          { quality: "xhdpi", title: "xhdpi", width: 352, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 528, height: 1050 },
        ],
      },
      {
        name: "back_door_open",
        title: "Back door open",
        image: [
          { quality: "hdpi", title: "hdpi", width: 383, height: 525 },
          { quality: "xhdpi", title: "xhdpi", width: 510, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 765, height: 1050 },
        ],
      },
      {
        name: "front_door_close",
        title: "Front door close",
        image: [
          { quality: "hdpi", title: "hdpi", width: 262, height: 525 },
          { quality: "xhdpi", title: "xhdpi", width: 352, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 528, height: 1050 },
        ],
      },
      {
        name: "front_door_open",
        title: "Front door open",
        image: [
          { quality: "hdpi", title: "hdpi", width: 383, height: 525 },
          { quality: "xhdpi", title: "xhdpi", width: 510, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 765, height: 1050 },
        ],
      },
      {
        name: "img_car_new",
        title: "Car with door",
        image: [
          { quality: "hdpi", title: "hdpi", width: 262, height: 525 },
          { quality: "xhdpi", title: "xhdpi", width: 352, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 528, height: 1050 },
        ],
      },
      {
        name: "car_without_doors",
        title: "Car without doors",
        image: [
          { quality: "hdpi", title: "hdpi", width: 236, height: 525 },
          { quality: "xhdpi", title: "xhdpi", width: 314, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 471, height: 1050 },
        ],
      },
      {
        name: "img_nagetive",
        title: "Negative image",
        image: [
          { quality: "hdpi", title: "hdpi", width: 262, height: 525 },
          { quality: "xhdpi", title: "xhdpi", width: 352, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 528, height: 1050 },
        ],
      },
      {
        name: "my_car",
        title: "Side view",
        image: [
          { quality: "hdpi", title: "hdpi", width: 435, height: 195 },
          { quality: "xhdpi", title: "xhdpi", width: 580, height: 260 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 870, height: 390 },
        ],
      },
    ],
    ios: [
      {
        name: "back_door_close",
        title: "Back door close",
        image: [
          { quality: "hdpi", title: "1X", width: 176, height: 350 },
          { quality: "xhdpi", title: "2X", width: 352, height: 700 },
          { quality: "xxhdpi", title: "3X", width: 528, height: 1050 },
        ],
      },
      {
        name: "back_door_open",
        title: "Back door open",
        image: [
          { quality: "hdpi", title: "hdpi", width: 277, height: 350 },
          { quality: "xhdpi", title: "xhdpi", width: 554, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 831, height: 1050 },
        ],
      },
      {
        name: "front_door_open",
        title: "Front door open",
        image: [
          { quality: "hdpi", title: "hdpi", width: 277, height: 350 },
          { quality: "xhdpi", title: "xhdpi", width: 554, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 831, height: 1050 },
        ],
      },
      {
        name: "front_door_close",
        title: "Front door close",
        image: [
          { quality: "hdpi", title: "hdpi", width: 176, height: 350 },
          { quality: "xhdpi", title: "xhdpi", width: 352, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 528, height: 1050 },
        ],
      },
      {
        name: "img_car_new",
        title: "Car with door",
        image: [
          { quality: "hdpi", title: "hdpi", width: 176, height: 350 },
          { quality: "xhdpi", title: "xhdpi", width: 352, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 528, height: 1050 },
        ],
      },
      {
        name: "car_without_doors",
        title: "Car without doors",
        image: [
          { quality: "hdpi", title: "hdpi", width: 157, height: 350 },
          { quality: "xhdpi", title: "xhdpi", width: 314, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 471, height: 1050 },
        ],
      },
      {
        name: "img_nagetive",
        title: "Negative image",
        image: [
          { quality: "hdpi", title: "hdpi", width: 176, height: 350 },
          { quality: "xhdpi", title: "xhdpi", width: 352, height: 700 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 528, height: 1050 },
        ],
      },
      {
        name: "my_car",
        title: "Side view",
        image: [
          { quality: "hdpi", title: "hdpi", width: 290, height: 130 },
          { quality: "xhdpi", title: "xhdpi", width: 580, height: 260 },
          { quality: "xxhdpi", title: "Xxhdpi", width: 870, height: 390 },
        ],
      },
    ],
  };

  invalidFileMessage: string = "Only jpg/jpeg, png files are allowed!";
  badgeCategoryLeaderboard: object = {
    SCORE_BADGE: "Driving Score",
    EFFICIENCY_BADGE: "Efficiency",
    DISTANCE_BADGE: "Distance",
  };
  public lsStorage=localStorage;
  apiStatusErrorMsg = { 403: "Unauthorized Access!", 500: "Internal Server Error!" };
  constructor(private http: HttpClient) { }

getAuthTokenV1(data: any) {
debugger;
    let authurl=`${this.baseURL}admin/auth`
    let headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(
       authurl,
        {...data},{headers:headers.headers}
      )
      .pipe(tap(res => res), catchError(err => of(err)));
  }
  getTokenForUser(username: string, passw: string) {
    let body = `client_id=${httpAuthBody.get("client_id")}&client_secret=${httpAuthBody.get("client_secret")
      }&password=${passw}&username=${username}&grant_type=${httpAuthBody.get("grant_type")}`;

    let res = this.http.post(`${this.authTokenURL}`, body, httpOptionsAuth).pipe(catchError(this.handleError));
    return res;
  }

  getRefreshToken(refresh_token: string) {

    let body = `client_id=${httpAuthBody.get("client_id")}&client_secret=${httpAuthBody.get("client_secret")
      }&refresh_token=${refresh_token}&grant_type=refresh_token`;
    let res = this.http.post(`${this.authTokenURL}`, body, httpOptionsAuth).pipe(catchError(this.handleError));
    return res;
  }


  getImageDetails(selectedModel: string, selectedPlatform: string, selectedColor: string): Observable<any[]> {
    let imgPathName = this.imageCarBaseURL;

    let res = this.http.get<any[]>(`${this.baseURL + "images?path=" + this.getUserRole()}/${imgPathName}${selectedModel
      }/${selectedColor}/${selectedPlatform}`, httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  //
  createBadge(badge: BadgeModel, tribeId: number): Observable<BadgeModel> {
    return this.http.post<BadgeModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/badges/create`,
      badge, httpOptions).pipe(catchError(this.handleError));
  }

  getTribe(id: number): Observable<TribeModel> {
    return this.http.get<TribeModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${id}`,
      httpOptions).pipe(catchError(this.handleError));
  }

  getTribeMembers(id: number,size:number,page:number): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseURL + this.getUserRole() + "/tribe/v2"}/${id}/members?size=${size}&page=${page}`,
      httpOptions).pipe(catchError(this.handleError));
  }

  deleteTribe(id: number) {
    return this.http.delete(`${this.baseURL + this.getUserRole() + "/tribe"}/${id}`, httpOptions);
  }
  //
  getTribes(): Observable<TribeModel[]> {
    return this.http.get<TribeModel[]>(`${this.baseURL + this.getUserRole() + "/tribe"}/list?model=Nexon`,
      httpOptions).pipe(catchError(this.handleError));
  }

  addTribe(tribe: TribeModel): Observable<TribeModel> {
    return this.http.post<TribeModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/create`,
      tribe, httpOptions).pipe(catchError(this.handleError));
  }
  //
  updateTribe(tribe: TribeModel, id: number) {
    return this.http.put(`${this.baseURL + this.getUserRole() + "/tribe"}/${id}/update`, tribe, httpOptions)
      .pipe(catchError(this.handleError));
  }

  //
  getTribeBadge(tribeId: number, badgeId: number): Observable<BadgeModel> {
    return this.http.get<BadgeModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/badges/${badgeId}`,
      httpOptions).pipe(catchError(this.handleError));
  }

  // https://{host}/ev/tribe/{tribeId}/badges/category/{category}
  getAllBadgeForCategory(tribeId: number, category: string): Observable<BadgeModel> {
    return this.http.get<BadgeModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/badges/category/${category}`, httpOptions).pipe(catchError(this.handleError));
  }

  //
  deleteBadge(tribeId: number, badgeId: number) {
    return this.http.delete(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/badges/${badgeId}`, httpOptions);
  }

  createBadges(tribe: BadgeModel, tribeId: number): Observable<BadgeModel> {
    return this.http.post<BadgeModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/badges/create`,
      tribe, httpOptions).pipe(catchError(this.handleError));
  }


  updatedTribeBadge(badge: BadgeModel, tribeId: number, badgeId: number): Observable<BadgeModel> {
    return this.http.put<BadgeModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/badges/${badgeId
      }/update`, badge, httpOptions).pipe(catchError(this.handleError));
  }

  //
  addGalleryImages(gallery: string[], tribeId: number): Observable<TribeModel> {
    return this.http.post<TribeModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/gallery`,
      gallery, httpOptions).pipe(catchError(this.handleError));
  }

  //
  deleteGalleryImages(tribeId: number, imageId: number) {
    return this.http.delete(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/gallery/${imageId}`, httpOptions);
  }

  //
  createFeed(feed: FeedModel, tribeId: number): Observable<FeedModel> {
    return this.http.post<FeedModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/posts/create`,
      feed, httpOptions).pipe(catchError(this.handleError));
  }


  deleteFeed(tribeId: number, feedId: number) {
    return this.http.delete(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/posts/${feedId}`, httpOptions);
  }

  //
  updatePost(feed: FeedModel, tribeId: number, feedId): Observable<FeedModel> {
    return this.http.put<FeedModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${tribeId}/posts/${feedId
      }/update`, feed, httpOptions).pipe(catchError(this.handleError));
  }
  //
  getPost(tribeId: number, postId: number): Observable<FeedModel[]> {
    return this.http.get<FeedModel[]>(`${this.baseURL + this.getUserRole() + "/tribe"
      }/${tribeId}/posts/${postId}`, httpOptions).pipe(catchError(this.handleError));
  }


  getAllCommentsForAPost(tribeId: number, postId: number): Observable<CommentModel[]> {
    return this.http.get<CommentModel[]>(`${this.baseURL + this.getUserRole() + "/tribe"
      }/${tribeId}/posts/${postId}/comments`, httpOptions).pipe(catchError(this.handleError));
  }


  deleteFeedComment(tribeId: number, feedId: number, commentId: number) {
    return this.http.delete(`${this.baseURL + this.getUserRole() +
      "/tribe"}/${tribeId}/posts/${feedId}/comments/${commentId}`, httpOptions);
  }

  getTribesDetails(id: number): Observable<TribeDetailsModel> {
    return this.http.get<TribeDetailsModel>(`${this.baseURL + this.getUserRole() + "/tribe"}/${id}`, httpOptions);
  }

  getToken() {
    let res = this.http.post(`${this.authTokenURL}`, httpAuthBody.toString(), httpOptionsAuth)
      .pipe(catchError(this.handleError));
    return res;
  }
  addAppVersion(appVersion: any) {
    let res = this.http.post(`${this.baseURL +'v2/' +this.getUserRole()}/app-version/supported-version`,
      appVersion, httpOptions).pipe(catchError(this.handleError));
    return res;
  }
  getAppVersion(appVersion: string) {
    let res = this.http.get(`${this.baseURL + this.getUserRole()}/app-version/supported-version?os=${appVersion}`,
      httpOptionsBasicAuthToken).pipe(catchError(this.handleError));
    return res;
  }

  getImages(context: string) {
    let res = this.http.get(`${this.baseURL}image?path=${context}`, httpOptions).pipe(catchError(this.handleError));
    return res;
  }
  getImagesByUrl(imgUrl: string): Observable<Blob> {

    let f = imgUrl.replace(/ /g, "");
    return this.http.get<Blob>(`${f}`, httpOptionsImgWithReponseType).pipe(catchError(this.handleError));
  }

  //
  uploadImage(filePath: string, fileContent: any) {
    let f = filePath.replace(/ /g, "");
    return this.http.put(`${f}`, fileContent, httpOptionsImg).pipe(catchError(this.handleError));
  }

  getAllCarImmobilizer(): Observable<MobilizerModel[]> {
    return this.http.get<MobilizerModel[]>(`${this.baseURL + this.getUserRole()}/vehicle-case`,
      httpOptions).pipe(catchError(this.handleError));
  }

  setCarImmobilizer(vehicleId: object): Observable<MobilizerModel[]> {
    let res = this.http.post<MobilizerModel[]>(`${this.baseURL + this.getUserRole()}/vehicle-case`,
      vehicleId, httpOptionsBasicAuthToken).pipe(catchError(this.handleError));
    return res;
  }

  setKycCarImmobilizer(caseId: number, statusobj: object): Observable<MobilizerModel[]> {
    let res = this.http.put<MobilizerModel[]>(`${this.baseURL + this.getUserRole()}/vehicle-case/${caseId}`,
      statusobj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  getVehicleDetails(vehicleId: string): Observable<MobilizerModel[]> {
    let res = this.http.get<MobilizerModel[]>(`${this.baseURL + this.getUserRole()}/vehicle-case?vehicleId=${vehicleId}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  changeVehicleStatus(caseId: number, statusobj: object): Observable<MobilizerModel[]> {
    let res = this.http.post<MobilizerModel[]>(`${this.baseURL + this.getUserRole()}/vehicle-case/${caseId}/${statusobj}`,
      statusobj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  getVehicleWakeup(vehicleId: any): Observable<any[]> {
    let res = this.http.get<any[]>(`${this.baseURL + this.getUserRole()}/vehicle-command/${vehicleId}/tcu/wakeup`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  getRemoteCommandStatus(vehicleId: any, correlationId: any): Observable<any[]> {
    let res = this.http.get<any[]>(`${this.baseURL + this.getUserRole()}/vehicle-command/${vehicleId
      }/status/${correlationId}`, httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  getAllNotification(): Observable<CustomNotificationModel[]> {
    let res = this.http.get<CustomNotificationModel[]>(`${this.baseURL + this.getUserRole()}/bulk-notification`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  getNotificationDetails(id: number): Observable<CustomNotificationModel> {
    let res = this.http.get<CustomNotificationModel>(`${this.baseURL + this.getUserRole()}/bulk-notification/${id}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  scheduleNotification(notificationObj: object): Observable<CustomNotificationModel[]> {
    let res = this.http.post<CustomNotificationModel[]>(`${this.baseURL + this.getUserRole()}/bulk-notification`,
      notificationObj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  updateNotification(notificationObj: object, notificationId: number): Observable<CustomNotificationModel[]> {
    let res = this.http.put<CustomNotificationModel[]>(`${this.baseURL + this.getUserRole()}/bulk-notification/${notificationId}`, notificationObj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  getAllProductLine(): Observable<any> {
    let role;
    role = this.getUserRole() == 'ev' ? "EV" : "PV";
    let res = this.http.get<any>(`${this.baseURL}parent-product-line?businessUnit=${role}`, httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  deleteNotification(id: number) {
    let res = this.http.delete(`${this.baseURL + this.getUserRole()}/bulk-notification/${id}`, httpOptions)
      .pipe(catchError(this.handleError));
    return res;
  }


  getAllFeedback(): Observable<FeedbackModel[]> {
    let res = this.http.get<FeedbackModel[]>(`${this.baseURL + this.getUserRole()}/user-feedback`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  getAllLeaderboard(tribeId: number, wom: string, categ: string): Observable<any[]> {

    let res = this.http.get<any[]>(`${this.baseURL + this.getUserRole()}/tribe/${tribeId
      }/leaderboard/top?count=10&duration=${wom}&category=${categ}`, httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  getTopLeaderBoards(tribeId: number, count: number, wom: string, type: number): Observable<any[]> {
    let res = this.http.get<any[]>(`${this.baseURL + this.getUserRole()}/tribe/${tribeId}/leaderboard/top?count=${count}&duration=${wom}&category=${type}`, httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  getAllFeature(): Observable<MasterFeatureListModel[]> {
    let res = this.http.get<MasterFeatureListModel[]>(`${this.baseURL + this.getUserRole()}/feature`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  createFeature(featureObj: object): Observable<MasterFeatureListModel[]> {
    let res = this.http.post<MasterFeatureListModel[]>(`${this.baseURL + this.getUserRole()}/feature`,
      featureObj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  updateFeature(featureObj: object, featureId: number): Observable<MasterFeatureListModel[]> {
    let res = this.http.put<MasterFeatureListModel[]>(`${this.baseURL + this.getUserRole()}/feature/${featureId}`,
      featureObj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  getFeature(featureId: number): Observable<any[]> {
    let res = this.http.get<any[]>(`${this.baseURL + this.getUserRole()}/feature/${featureId}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  deleteFeature(id: number) {
    let res = this.http.delete(`${this.baseURL + this.getUserRole()}/feature/${id}`, httpOptions)
      .pipe(catchError(this.handleError));
    return res;
  }


  getAllVehicleProfile(): Observable<any[]> {
    let res = this.http.get<any[]>(`${this.baseURL + this.getUserRole()}/vehicle-profile`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  createVehicleProfile(profileObj: object): Observable<any[]> {
    let res = this.http.post<any[]>(`${this.baseURL + this.getUserRole()}/vehicle-profile`,
      profileObj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  updateVehicleProfile(profileObj: object, vehicleProfileId: number): Observable<any[]> {
    let res = this.http.put<any[]>(`${this.baseURL + this.getUserRole()}/vehicle-profile/${vehicleProfileId}`,
      profileObj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  getVehicleConfiguration(productLine: string): Observable<any[]> {
    let res = this.http.get<any[]>(`${this.baseURL + this.getUserRole()}/vehicle-configuration/${productLine}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  deleteProfile(id: number) {
    let res = this.http.delete(`${this.baseURL + this.getUserRole()}/vehicle-profile/${id}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  getProfileDetails(id: number): Observable<any[]> {
    let res = this.http.get<any[]>(`${this.baseURL + this.getUserRole()}/vehicle-profile/${id}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  downloadPurchasedPlan(from: any, to: any): Observable<any> {
    return this.http.get<any>(`${this.baseURL + this.getUserRole()}/download/purchases?from=` + from + `&to=` + to,
      httpOptionsDownload).pipe(catchError(this.handleError));
  }


  uploadPurchasedPlan(fileObj: any): Observable<any> {
    return this.http.post<any>(`${this.baseURL + this.getUserRole()}/upload/invoices`,
      fileObj, httpOptionsUpload).pipe(catchError(this.handleError));
    // return res;
  }


  getPurchasedPlan(filterObj: object, pagNo: number, pageSize: number): Observable<PurchasedPlanModel[]> {
    let res = this.http.post<PurchasedPlanModel[]>(`${this.baseURL + this.getUserRole()}/purchased-plan?page=` +
      pagNo + `&size=` + pageSize, filterObj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  createSubscriptionPlan(SubPlanObj: object): Observable<any[]> {
    let res = this.http.post<any[]>(`${this.baseURL + this.getUserRole()}/subscription-plan`,
      SubPlanObj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  getSubscriptionPlan(): Observable<SubscriptionPlanModel[]> {
    let res = this.http.get<SubscriptionPlanModel[]>(`${this.baseURL + this.getUserRole()}/subscription-plan`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  getSubscriptionPlanById(subId: number): Observable<SubscriptionPlanModel[]> {
    let res = this.http.get<SubscriptionPlanModel[]>(`${this.baseURL + this.getUserRole()}/subscription-plan/${subId}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  deleteSubscritionPlan(subId: number) {
    let res = this.http.delete(`${this.baseURL + this.getUserRole()}/subscription-plan/${subId}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  updateSubscritionPlan(subObj: object, subId: number): Observable<any[]> {
    let res = this.http.put<any[]>(`${this.baseURL + this.getUserRole()}/subscription-plan/${subId}`,
      subObj, httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  getSubscriptionPlanForUserToPurchase(subId: string): Observable<SubscriptionPlanModel[]> {
    let res = this.http.get<SubscriptionPlanModel[]>(`${this.baseURL + this.getUserRole()}/subscription-plan/${subId}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }


  getPurchasedPlansOfUserByChassisNo(subId: string): Observable<SubscriptionPlanModel[]> {
    let res = this.http.get<SubscriptionPlanModel[]>(`${this.baseURL + this.getUserRole()}/purchased-plan?chassisNumber/${subId}`,
      httpOptions).pipe(catchError(this.handleError));
    return res;
  }

  getUserDetailsByChassisNo(chassisNo: string): Observable<any> {
    let uObj = { chassisNumber: chassisNo };
    let res = this.http.post<any>(`${this.baseURL + this.getUserRole()}/vehicle-user`, uObj, httpOptionsBasicAuthToken)
      .pipe(catchError(this.handleError));
    return res;
  }

  getUserRole() {
    return this.getLocal("trole");
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  hasToken():boolean{
    return !!localStorage.getItem("accesst");
  }
  hasKey(key):boolean{
    //this.consoleMessages("test",!!localStorage.getItem(key));
    return !!localStorage.getItem(key);
  }
  setLocal(key,instr){
    if(key!='access_log'){
     localStorage.setItem(key,instr);
      return true;
    }
    let encrypt_string= encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(instr), 'KS#$R$%@!#@!$*&^').toString());
   localStorage.setItem(key,encrypt_string);
  }
  getLocal(key){
    if(key!='access_log'){
      return localStorage.getItem(key);
    }else{
    if(this.hasKey(key)==true){
      try{
        let encrypt_string=localStorage.getItem(key);
        var deData= CryptoJS.AES.decrypt(decodeURIComponent(encrypt_string),'KS#$R$%@!#@!$*&^');
        let decryptedInfo = JSON.parse(deData.toString(CryptoJS.enc.Utf8));
        return decryptedInfo;
        }catch(e){
          return false;
        }
    }else{
      return false;
    }
  }


  }
  removelocal(){
    this.lsStorage.clear();
  }

}
