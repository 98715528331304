export const environmentkeys = {
  env: 'qa',
  production: true,
  qa: {
    BASE_URL: "https://qa.cvp-api-nonprod.pv.tatamotors/",
    AUTH_BASE_URL: "https://qa.cvp-api-nonprod.pv.tatamotors/",
    BASE_IMAGES_URL: "https://cvp-qa-content.pv.tatamotors/",
    BASETOKEN: "Y3ZwLXVzZXI6cU84IUJ0cDl2WXVeRkdSdVlQ",
    CLIENT_ID: "cvp_layer4_api",
    CLIENT_SECRET: "b59089bb-c4d6-4f97-a231-c624594fed33",
    grant: "password",
    imageCarQueryString:"new-test/vehicle"
  },
};
